import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "src/app/user.service";
import { HelperServiceService } from "src/app/helper-service.service";
import { IdVerificationService } from "src/app/id-verification/id-verification.service";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-share-request-user",
  templateUrl: "./share-request-user.component.html",
  styleUrls: ["./share-request-user.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ShareRequestUserComponent implements OnInit, OnDestroy {
  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    public router: Router,
    private helper: HelperServiceService,
    private idVerificationService: IdVerificationService,
    public translate: TranslateService,
  ) {}

  logo: string;
  projectColor: string;
  projectTextColorClass: string;
  message: string;
  invitationKey: string;
  headerInfo: {};
  headerClasses: {};
  kvkResults: any;
  initiated: boolean;
  consent: boolean; // can be: false - no consent, true - consent
  project: string;
  contributorType: string;
  displayLoader: boolean;
  shareKey: string;
  activeUser = 1;
  showClaimButton = false;
  groups = {};
  requestKey: string;
  rawdata = {};
  existingProjects = false;
  existingProjectList = [];
  returnedError = false;
  investorProjectKey;
  parseInvestorsStatus: Subscription;
  investor = undefined;
  investorRelatedParties = undefined;
  investorActiveParty = undefined;
  organizationDetails;
  organizationLogo;
  privacyLink = "https://www.meshid.com/privacy-policy";
  isAutomaticRequest;
  showGeneralLoadingSpinner;
  hasRedirectUrl;

  allowReuse = true;
  investorProjectToMergeTo;

  parseContentReturn(returnedAction) {
    if (
      typeof returnedAction.method === "string" &&
      returnedAction.method !== ""
    ) {
      // allow only strings as acceptable method name
      let params = "";
      // if (Array.isArray(returnedAction.params)) {
      params = returnedAction.params;
      // }
      try {
        this[returnedAction.method](params); // call it
      } catch (error) {
        console.log(returnedAction.method, "is not declared as method!", error);
      }
    } else {
      console.log("**method name not string or empty string");
    }
  }
  ngOnInit() {
    this.logo = "assets/images/meshId_logo.svg";
    this.displayLoader = true;
    this.projectColor = "#f5f5f5";
    // the default is already set even though background color is not retreivedP
    // this.projectTextColorClass = this.verificationsService.getProjectTextColorClass();
    this.message = "Please wait while we fetch the sharing detail";
    this.consent = false;
    this.project = "";
    this.initiated = false;
    this.headerInfo = {
      headerType: "full", // 'inline',
      // 'headerTitle': 'Investor Dashboard',
      activeNavLink: "funds",
      projectType: "Investor",
      hideMainNav: true,
      isInvestor: true,
    };
    this.headerClasses = {
      backgroundClass: "tealish-gradient-bg",
      sectionClass: "ic_height_auto", // 'ic_height_auto',
    };
    this.groups = {
      legalRepresentatives: [
        {
          section_class: "results d-flex flex-column justify-items-center",
          is_collapsable: true,
          show_list_filter: false,
          show_verification_filter: false,
          // tslint:disable-next-line:max-line-length
          emptyMessage: "",
          result_details: [],
        },
      ],
    };
    this.showGeneralLoadingSpinner = true;
    this.parseInvestorsStatus =
      this.idVerificationService.detectActiveInvestorsStatus.subscribe(
        (response) => {
          this.parseInformationRequest(
            this.investor,
            this.investorRelatedParties,
            response,
          );
        },
      );
    this.shareKey = this.activatedRoute.snapshot.params.shareKey;
    this.userService
      .getEndPoint(
        "/api/investor/requests/" + this.shareKey + "/organization",
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Error while fetching organization details");
            return;
          }
          this.organizationDetails = response;
        },
        (error) => {
          console.log("Error while fetching organization details");
        },
      );

    this.userService
      .getEndPoint(
        "/api/investor/requests/" + this.shareKey + "/organization/logo",
        { responseType: "blob" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        async (responseLogo) => {
          if (responseLogo === undefined) {
            console.log("Error while fetching organization logo");
            this.organizationLogo = "assets/images/logo-alternate.svg";
            return;
          }
          if (responseLogo["type"] === "application/json") {
            this.logo = JSON.parse(await (responseLogo as Blob).text()).link;
          } else {
            const urlCreator = window.URL
              ? window.URL
              : (window as any).webkitURL;
            this.organizationLogo = urlCreator.createObjectURL(responseLogo);
          }
        },
        (error) => {
          console.log("Error while fetching organization logo");
        },
      );

    this.userService
      .getEndPoint(
        "/api/investor/requests/" + this.shareKey,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Error while fetching investor record");
            return;
          }

          console.log("project response  ****", response);

          if (
            response["projectBranding"] &&
            response["projectBranding"]["projectColor"]
          ) {
            this.projectColor = response["projectBranding"]["projectColor"];
          }

          this.allowReuse =
            response["request"].record.allowReuse != undefined
              ? response["request"].record.allowReuse
              : true;
          this.investorProjectToMergeTo =
            response["request"].record.investorProject;

          const spProjectKey = response["serviceProviderProjectKey"];
          // if (spProjectKey) {
          //   this.userService.getEndPoint('/api/projects/' + spProjectKey + '/logo',
          //     { responseType: 'blob' }, { observe: 'body' }, 'funds').subscribe(async response2 => {

          //       if (response2 === undefined) {
          //         console.log('Error while fetching organization logo');
          //         return;
          //       }

          //       if (response2['type'] === 'application/json') {
          //         this.logo = JSON.parse(await (response2 as Blob).text()).link;
          //       } else {
          //         const urlCreator = window.URL ? window.URL : (window as any).webkitURL;
          //         this.organizationLogo = urlCreator.createObjectURL(response2);
          //       }
          //     }, error => {
          //       console.log('Error while fetching organization logo');
          //     }
          //     );
          // }
          if (
            response &&
            response["projectBranding"] &&
            response["projectBranding"]["projectLogo"] &&
            !this.organizationLogo
          ) {
            this.organizationLogo = response["projectBranding"]["projectLogo"];
          }
          response["request"]["record"]["evidenceTypes"] =
            this.idVerificationService.concatEvidenceTypesWithDigitalVerifications(
              response["request"]["record"]["evidenceTypes"],
              response["request"]["record"]["digitalVerifications"],
            );
          this.investor = response["request"];
          this.investorRelatedParties = response["relatedParties"];
          this.investorProjectKey =
            response["request"].record.investorProjectKey;
          this.hasRedirectUrl = this.investor.record.redirectUr;
          if (
            response["projectBranding"] &&
            response["projectBranding"].privacyUrl
          ) {
            this.privacyLink = response["projectBranding"].privacyUrl;
          }
          this.userService
            .getEndPoint(
              "/api/projects?status=active",
              { responseType: "json" },
              { observe: "body" },
              "funds",
            )
            .subscribe(
              (responseProjects) => {
                if (response === undefined) {
                  console.log("We could retrieve the list of active projects");
                  return;
                }
                // there are existing projects
                if (responseProjects["records"].length !== 0) {
                  // the project is already in the investor project list, so no reason to show the project select menu
                  const evidenceIndex = responseProjects["records"].findIndex(
                    (project) => project.key === this.investorProjectKey,
                  );
                  if (evidenceIndex === -1) {
                    this.existingProjects = true;
                    this.existingProjectList = responseProjects["records"];
                  }
                } else {
                }
                if (
                  this.investor.status &&
                  this.investor.status.toUpperCase() === "SHARED"
                ) {
                  // evidence has already been claimed
                  if (!this.investor.record.isAutomaticRequest) {
                    let url = "/investor/dashboard/";
                    if (this.investor.record.investorProjectKey) {
                      url =
                        "/investor/dashboard/" +
                        this.investor.record.investorProjectKey;
                    }
                    this.router.navigate([url]);
                  } else {
                    this.router.navigate([
                      `investor/dashboard/${this.investor.record.investorProjectKey}`,
                    ]);
                  }
                } else {
                  $(".organizational-details").removeClass("d-none");
                  this.showGeneralLoadingSpinner = false;
                }
                this.idVerificationService.setActiveInvestorDirect(
                  response["request"].key,
                  response["request"].record,
                );
                this.requestKey = this.investor.key;
                if (
                  !this.idVerificationService.getActiveInvestorDetaisl()
                    .isAutomaticRequest
                ) {
                  this.parseInformationRequest(
                    this.investor,
                    this.investorRelatedParties,
                  );
                } else {
                  this.isAutomaticRequest = true;
                }
                this.showClaimButton = true;
              },
              (error) => {
                // alert('The following error occured when retrieving the active projects ' + error.message);
                console.log(
                  "The following error occured when retrieving the active projects",
                  error.message,
                );
              },
            );
        },
        (error) => {
          this.showGeneralLoadingSpinner = false;
          console.log(
            "Following error occured while fetching investor record",
            error.message,
          );
          // alert( this.message = this.translate.instant('shareRequestUser.errorMessage1') + error.message);
          this.returnedError = true;
          this.showClaimButton = true;
          const url = "/investor/dashboard/";
          this.router.navigate([url]);
          return;
        },
      );
  }
  parseInformationRequest(investor, relatedParties, activeRelatedParty?) {
    const result = investor.record;
    const key = investor.key;
    this.requestKey = investor.key;
    let title = "";
    if (result.typeOfInvestor === "LegalPerson") {
      title = result.investorFields.RegisteredName;
    }
    if (result.typeOfInvestor === "NaturalPerson") {
      title =
        result.investorFields.FirstName + " " + result.investorFields.LastName;
    }

    const emptyEvidenceTypes = result.evidenceTypes.length === 0;
    const group = {
      section_class:
        "results d-flex flex-column justify-items-center list-preview-only",
      // 'group_name' : ' ',  // if empty group name is passed it still renders an empty title control which has margins
      is_collapsable: true,
      // 'show_list_filter': true,
      isPartial: false,
      display_orange_dot: false,
      result_details: [],
      filter: [],
    };
    const personParsed = {
      key: key,
      result_title: title,
      result_container_class:
        "person-list-entry result-container my-0 minified investor-list-entry" +
        (false ? "" : " expanded"),
      isNew: false,
      onArrowClickMethod: false ? "onExpandActiveBox" : null,
      result_title_classes: "mt-0",
      group_result_title:
        "pi_result_" + key.split(":").pop() + new Date().getTime(),
      elements: <any>[
        {
          type: "documentUploadInner",
          class: emptyEvidenceTypes ? "d-none" : "",
          typeOfInvestor: result.typeOfInvestor,
          evidenceTypes: result.evidenceTypes,
          requestKey: key,
          hideButtons: true,
          previewMode: true,
          beforeClaim: true,
        },
        {
          type: "tabbed_content",
          class: !emptyEvidenceTypes
            ? "half-width-wrapper mt-3 d-none"
            : "half-width-wrapper mt-3",
          params: {
            sectionTitle: this.translate.instant("appSpecific.Summary"),
            sectionTitleClass: "title-t2",
            showScreeningElementsResults: true,
            tabKey: key + "_details",
            tabGroup: key,
            tabs: [
              {
                id: "entity_type",
                status: "active",
                content: this.generateSingleInvestorContentFields(
                  result.investorFields,
                  investor.record.typeOfInvestor,
                  1,
                ),
              },
            ],
          },
        },
      ],
    };
    if (relatedParties && relatedParties.length !== 0) {
      // related party digital verificaiton group
      const activeRelatedPartyDV =
        this.idVerificationService.getActiveInvestorDetaisl()[
          "activeRelatedParty"
        ]
          ? this.idVerificationService.getActiveInvestorDetaisl()[
              "activeRelatedParty"
            ]["record"]["digitalVerifications"]
          : undefined;
      const activeRelatedPartyKey = activeRelatedParty
        ? activeRelatedParty["key"]
        : "";
      const emptyRPEvidenceTypes =
        activeRelatedParty &&
        activeRelatedParty["record"]["evidenceTypes"].length === 0;

      let dvStatuses;
      let tabs;
      tabs = [
        {
          name: this.translate.instant("appSpecific.ProfileDetails"),
          activates: activeRelatedParty
            ? activeRelatedParty["key"] + "_details"
            : "",
          active: emptyRPEvidenceTypes ? true : false,
        },
      ];
      if (!emptyRPEvidenceTypes) {
        tabs.unshift({
          name: this.translate.instant("appSpecific.Evidence"),
          activates: activeRelatedParty
            ? activeRelatedParty["key"] + "_evidence"
            : "",
          active: true,
        });
      }
      if (activeRelatedPartyDV && activeRelatedPartyDV.length > 0) {
        // if there are digital verifications parse the statuses
        dvStatuses =
          this.idVerificationService.parseFundDVStatuses(activeRelatedPartyDV);
        // add the status to the RP group
        tabs.splice(1, 0, {
          name: this.translate.instant("appSpecific.DigitalVerifications"),
          activates: activeRelatedPartyKey + "_checks",
        });
      }
      personParsed.elements.push({
        type: "relatedPartiesList",
        requestKey: key,
        relatedParties: relatedParties,
        count: relatedParties.length,
        activeRelatedPartyKey: activeRelatedPartyKey,
        activeRelatedParty: activeRelatedParty ? activeRelatedParty.record : "",
        activeRelatedPartyFields: activeRelatedParty
          ? this.generateSingleInvestorContentFields(
              activeRelatedParty["record"]["investorFields"],
              activeRelatedParty["record"]["typeOfInvestor"],
            )
          : undefined,
        beforeClaim: true,
        emptyRPEvidenceTypes: emptyRPEvidenceTypes,
        tabGroup: activeRelatedParty ? activeRelatedParty["key"] : "",
        tabs: tabs,
        dvStatuses: dvStatuses,
        activeRelatedPartyDV: activeRelatedPartyDV,
        vtype: "claimScreen",
        isInvestor: true,
      });
    }

    personParsed["elements"].unshift({
      type: "dashboardTabs",
      tabGroup: key,
      tabs: [
        {
          name: this.translate.instant("appSpecific.ProfileDetails"),
          activates: key + "_details",
          active: emptyEvidenceTypes ? true : false,
        },
      ],
    });
    if (!emptyEvidenceTypes) {
      personParsed["elements"][0]["tabs"].unshift({
        name: this.translate.instant("appSpecific.Evidence"),
        activates: key + "_evidence",
        active: true,
      });
    }
    // digital verifications tab
    if (result.digitalVerifications && result.digitalVerifications.length) {
      personParsed["elements"][0]["tabs"].splice(1, 0, {
        name: this.translate.instant("appSpecific.DigitalVerifications"),
        activates: key + "_checks",
      });
      personParsed["elements"].splice(
        2,
        0,
        // VERIFICATION STATUSES IS THE ELEMENT THAT DISPLAYS ALL THE VERIFICATION STATUSES ON THE DASHBOARD
        {
          type: "verification_statuses",
          tabGroup: key,
          tabKey: key + "_checks",
          class: "px-3 mt-4 d-none",
          params: {
            vtype: "claimScreen",
            requestKey: key,
            statuses: this.idVerificationService.parseFundDVStatuses(
              result.digitalVerifications,
            ),
          },
        },
      );
    }

    group["result_details"].push(personParsed);
    this.groups["legalRepresentatives"] = [];
    this.groups["legalRepresentatives"].push(group);
  }
  generateSingleInvestorContentFields(fields, investorType, fieldspart = 1) {
    let ret = [];
    if (investorType === "NaturalPerson") {
      if (fieldspart === 1) {
        const showAddress: boolean =
          fields.AddressStreet ||
          fields.AddressNumber ||
          fields.AddressPostalCode ||
          fields.AddressCity ||
          fields.AddressCountry;
        ret = [
          { key: "Title", content: fields.Title },
          {
            key: this.translate.instant("evidenceFields.FirstName"),
            content: fields.FirstName,
          },
          {
            key: this.translate.instant("evidenceFields.MiddleNames"),
            content: fields.MiddleNames,
          },
          {
            key: this.translate.instant("evidenceFields.LastName"),
            content: fields.LastName,
          },
          {
            key: this.translate.instant("evidenceFields.FormerFirstNames"),
            content: fields.FormerFirstNames,
          },
          {
            key: this.translate.instant("evidenceFields.FormerLastNames"),
            content: fields.FormerLastNames,
          },
          {
            key: this.translate.instant("evidenceFields.Gender"),
            content: fields.Gender,
          },
          {
            key: this.translate.instant("evidenceFields.DateOfBirth"),
            content: fields.DateOfBirth
              ? this.helper.formatISODate(fields.DateOfBirth.toString())
              : undefined,
          },
          {
            key: this.translate.instant("evidenceFields.CountryOfBirth"),
            content: this.idVerificationService.returnNameOfCountryFromValue(
              fields.CountryOfBirth,
            ),
          },
          {
            key: this.translate.instant("evidenceFields.Nationality"),
            content: this.idVerificationService.returnNameOfCountryFromValue(
              fields.Nationality,
            ),
          },
          {
            key: this.translate.instant("evidenceFields.DualNationality"),
            content: this.idVerificationService.returnNameOfCountryFromValue(
              fields.DualNationality,
            ),
          },
          {
            key: this.translate.instant("evidenceFields.CountryOfResidence"),
            content: this.idVerificationService.returnNameOfCountryFromValue(
              fields.CountryOfResidence,
            ),
          },
          {
            key: this.translate.instant("evidenceFields.EmailAddress"),
            content: fields.EmailAddress,
          },
          {
            key: this.translate.instant("evidenceFields.Occupation"),
            content: fields.Occupation,
          },
          {
            key: this.translate.instant("evidenceFields.TaxIDNumber"),
            content: fields.TaxIDNumber,
          },
          {
            key: "Label",
            content: showAddress
              ? this.translate.instant("evidenceFields.Address")
              : undefined,
            title: true,
          },
          {
            key: this.translate.instant("evidenceFields.AddressStreet"),
            content: fields.AddressStreet,
          },
          {
            key: this.translate.instant("evidenceFields.AddressNumber"),
            content: fields.AddressNumber,
          },
          {
            key: this.translate.instant("evidenceFields.AddressPostalCode"),
            content: fields.AddressPostalCode,
          },
          {
            key: this.translate.instant("evidenceFields.AddressCity"),
            content: fields.AddressCity,
          },
          {
            key: this.translate.instant("evidenceFields.AddressCountry"),
            content: this.idVerificationService.returnNameOfCountryFromValue(
              fields.AddressCountry,
            ),
          },
        ];
      } else if (fieldspart === 2) {
        ret = [];
      }
    } else if (investorType === "LegalPerson") {
      if (fieldspart === 1) {
        const showAddress: boolean =
          fields.RegisteredAddressStreet ||
          fields.RegisteredAddressNumber ||
          fields.RegisteredAddressPostalCode ||
          fields.RegisteredAddressCity ||
          fields.CountryOfRegistration;
        ret = [
          {
            key: this.translate.instant("evidenceFields.RegisteredName"),
            content: fields.RegisteredName,
          },
          {
            key: this.translate.instant("evidenceFields.TradeNames"),
            content: fields.TradeNames,
          },
          {
            key: this.translate.instant("evidenceFields.RegistrationNumber"),
            content: fields.RegistrationNumber,
          },
          {
            key: this.translate.instant("evidenceFields.DateOfRegistration"),
            content: fields.DateOfRegistration
              ? this.helper.formatISODate(fields.DateOfRegistration.toString())
              : undefined,
          },
          {
            key: this.translate.instant("evidenceFields.LegalEntityIdentifier"),
            content: fields.LegalEntityIdentifier,
          },
          {
            key: this.translate.instant("evidenceFields.CountryOfRegistration"),
            content: this.idVerificationService.returnNameOfCountryFromValue(
              fields.CountryOfRegistration,
            ),
          },
          {
            key: this.translate.instant("evidenceFields.Regulated"),
            content: fields.Regulated,
          },
          {
            key: this.translate.instant("evidenceFields.TaxNumber"),
            content: fields.TaxNumber,
          },
          {
            key: "Label",
            content: showAddress
              ? this.translate.instant("evidenceFields.RegisteredAddress")
              : undefined,
            title: true,
          },
          {
            key: this.translate.instant("evidenceFields.AddressStreet"),
            content: fields.RegisteredAddressStreet,
          },
          {
            key: this.translate.instant("evidenceFields.AddressNumber"),
            content: fields.RegisteredAddressNumber,
          },
          {
            key: this.translate.instant("evidenceFields.AddressPostalCode"),
            content: fields.RegisteredAddressPostalCode,
          },
          {
            key: this.translate.instant("evidenceFields.AddressCity"),
            content: fields.RegisteredAddressCity,
          },
          {
            key: this.translate.instant("evidenceFields.AddressCountry"),
            content: this.idVerificationService.returnNameOfCountryFromValue(
              fields.CountryOfRegistration,
            ),
          },
        ];
      } else if (fieldspart === 2) {
        ret = [];
      }
    }
    return ret;
  }

  onClaimData(existingProjects) {
    let payload = {};
    if (existingProjects) {
      $("#claimData2").html(
        '<img style="width:20px;height:20px;" src="/assets/images/spinner.svg"/>',
      );
      $(".expire-lightbox .form-content-container").addClass("non-interactive");
      $("#rejectData").addClass("non-interactive");

      if (this.investorProjectToMergeTo) {
        payload = {
          projectKey: this.investorProjectToMergeTo,
        };
      } else if ($("#existing_project").is(":checked")) {
        payload = {
          projectKey: $("#project_list_id option:selected").val(),
        };
      }
    } else {
      $("#rejectData").addClass("non-interactive");
      $("#claimData").html(
        '<img style="width:20px;height:20px;" src="/assets/images/spinner.svg"/>',
      );
      $(".expire-lightbox .form-content-container").addClass("non-interactive");
      if (this.investorProjectKey) {
        payload = {
          projectKey: this.investorProjectKey,
        };
      }
    }
    $(".entity-error-message").addClass("d-none");
    this.userService
      .postEndPoint(
        "/api/investor/requests/" + this.requestKey + "/claim",
        payload,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          // $('#claimData').html('preLoaderContent');
          if (response === undefined) {
            alert(this.translate.instant("shareRequestUser.errorMessage2"));
            console.log("Error while  claiming data");
            return;
          }
          if (
            this.idVerificationService.getActiveInvestorDetaisl()
              .typeOfInvestor !== "AutomaticRequest"
          ) {
            const url =
              "/investor/dashboard/" + response["projectKey"].toString();
            this.router.navigate([url]);
          } else {
            const url =
              "/investor/dashboard/" + response["projectKey"].toString();
            this.router.navigate([url]);
          }
        },
        (error) => {
          // $('#claimData').html('preLoaderContent');
          console.log(
            "Following error occured while claiming data",
            error.message,
          );
          alert(this.translate.instant("shareRequestUser.errorMessage2"));
          $("#claimData2").html("Done");

          if (error.status === 400) {
            $(".expire-lightbox .form-content-container").removeClass(
              "non-interactive",
            );
            $(".entity-error-message").removeClass("d-none");
          } else {
            this.refreshDataAddress();
          }
          return;
        },
      );
  }
  onRejectData() {
    const preLoaderContent = $("#rejectData").html();
    $("#rejectData").html(
      '<img style="width:20px;height:20px;" src="/assets/images/spinner.svg"/>',
    );
    this.userService
      .postEndPoint(
        "/api/investor/requests/" + this.requestKey + "/reject",
        {},
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          $("#rejectData").html(preLoaderContent);
          if (response === undefined) {
            this.refreshDataAddress();
            alert(this.translate.instant("shareRequestUser.errorMessage3"));
            console.log("Error while rejecting data");
            return;
          }
          this.router.navigate(["/investor/dashboard"]);
        },
        (error) => {
          this.refreshDataAddress();
          $("#rejectData").html(preLoaderContent);
          alert(this.translate.instant("shareRequestUser.errorMessage3"));
          console.log("Error occured while rejecting data", error.message);
          return;
        },
      );
  }
  refreshDataAddress() {
    const currentRoute = this.router.url;
    this.router
      .navigateByUrl("/verifications", { skipLocationChange: true })
      .then(() => {
        this.router.navigate([currentRoute]); // navigate to same route
      });
  }
  showClaimScreen() {
    $(".claim-form").removeClass("d-none");
  }
  hideClaimScreen() {
    $(".claim-form").addClass("d-none");
  }
  ngOnDestroy() {
    this.parseInvestorsStatus.unsubscribe();
  }
  hideOrganizationalDetails() {
    if (!this.isAutomaticRequest) {
      $(".organizational-details").addClass("d-none");
    } else {
      // if it's an automatic request and there are no existing projects,
      // this button should immediately create a new project and redirect to the investor dashboard
      if (!this.existingProjects || !this.allowReuse) {
        $(".organizational-details").addClass("d-none");
        // also show the loading button
        this.showGeneralLoadingSpinner = true;
        this.onClaimData(undefined);
      } else {
        $(".organizational-details").addClass("d-none");
        // else show the project selection screen
        this.showClaimScreen();
      }
    }
    $(".c-builder-container").removeClass("d-none");
  }
}
