<div
  id="evidenceFields"
  class="evidence-fields"
  [ngSwitch]="evidenceField.type"
  *ngFor="let evidenceField of element.params.evidenceCategories"
>
  <!-- COMPANY SEARCH -->
  <div
    class="company-review-wrapper"
    [ngClass]="{ 'd-none': !element.params.selectedCountryValueReview }"
    *ngIf="element.params.permissionKompany && !element.params.manualSPfields"
  >
    <div *ngSwitchCase="'CompanySearch'" class="addRelatedProfile">
      <div
        class="element-wrapper showOrHideDetails"
        [ngClass]="element.class ? element.class : ''"
      >
        <a
          (click)="onShowAdditionalInformation($event)"
          class="more-details-button more-details-additional-info"
          *ngIf="!showAdditionalInformation"
        >
          <img src="/assets/images/ico_green.svg" />
          <span>{{
            element.params.companySearchCompleted ||
            element.params.lockedFields.length
              ? ("kompanySearch.button2" | translate)
              : ("kompanySearch.button3" | translate)
          }}</span>
        </a>
        <a
          (click)="onCancelAdditionalInformation($event)"
          class="more-details-button more-details-additional-info-cancel"
          *ngIf="!!showAdditionalInformation"
        >
          <img src="/assets/images/ico-minus-green.svg" />
          <span>{{ "common.hide" | translate }}</span>
        </a>
        <app-kompany-seach
          *ngIf="showAdditionalInformation"
          [currentlySelectedCountry]="currentlySelectedCountry"
          [element]="element.params.kompanyInitialObject"
        ></app-kompany-seach>
      </div>
    </div>
  </div>
  <!-- INPUT DATE -->
  <div *ngSwitchCase="'date'" class="element-wrapper">
    <!-- Input Date -->
    <span class="input-label font-weight-bold">
      {{ evidenceField.label }}
      <sup class="sup-required">{{
        (element.investorSide && !evidenceField.optional) ||
        element.params.replaceFlow
          ? "*"
          : ""
      }}</sup>
    </span>
    <app-my-date-picker
      name="{{ evidenceField.name }}"
      id="{{ evidenceField.id }}"
      method="setMinEndDate"
      dateInputId="{{ evidenceField.name }}"
      isReqiured="{{
        element.investorSide || element.params.replaceFlow ? 'true' : undefined
      }}"
      value="{{ parseDate(evidenceField.value) }}"
      startDate="{{
        evidenceField.startDate
          ? evidenceField.startDate
          : evidenceField.minDate
          ? evidenceField.minDate
          : ''
      }}"
      maxDate="{{ evidenceField.maxDate }}"
      minDate="{{ evidenceField.minDate }}"
      (dateChange)="onEmit(elementService.parseDateContentReturn($event))"
      [(ngModel)]="evidenceField.id"
    ></app-my-date-picker>
    <div
      class="error-field"
      [attr.validatorMessage]="element.params.validatorMessage"
      [attr.validatorMessageField]="element.params.validatorMessage"
    ></div>
    <div
      class="error-field-wrong-format d-none"
      [attr.validatorMessage]="
        '*the date must be in the correct format: dd.mm.yyyy '
      "
    ></div>
    <div
    class="error-field-not-older d-none"
    [attr.validatorMessage]="
      '*The document is older than the specification'
    "
  ></div>
  </div>
  <!-- INPUT TEXT -->
  <div
    class="element-wrapper"
    [ngClass]="{ 'no-border': evidenceField.noBottomBorder }"
    *ngSwitchCase="'inputText'"
  >
    <div
      [ngClass]="{
        'd-none':
          (evidenceField.displayCondition || evidenceField.hideCondition) &&
          !isFieldChecked(
            evidenceField.displayCondition,
            evidenceField.hideCondition,
            evidenceField.id
          )
      }"
    >
      <span class="input-label d-flex align-items-center font-weight-bold"
        >{{ evidenceField.label }}
        <sup class="sup-required">{{
          (element.investorSide && !evidenceField.optional) ||
          (element.params.replaceFlow && !evidenceField.optional)
            ? "*"
            : ""
        }}</sup>
        <app-tooltip
          *ngIf="evidenceField.toolTip"
          [tooltipText]="evidenceField.toolTip"
          [icon]="'/assets/images/question-circle-solid.svg'"
        ></app-tooltip>
      </span>
      <input
        type="text"
        class="text-input"
        id="{{ evidenceField.id }}"
        name="{{ evidenceField.name }}"
        [attr.isValid]="true"
        [attr.isRequired]="
          (element.investorSide && !evidenceField.optional) ||
          (element.params.replaceFlow && !evidenceField.optional)
            ? 'true'
            : 'false'
        "
        [attr.isMaxLength]="evidenceField.maxLength"
        [attr.isMinLength]="evidenceField.minLength"
        [attr.skipParse]="evidenceField.skipParse"
        [attr.patternMatch]="evidenceField.patternMatch"
        (keyup)="onEmit(elementService.OnInputKeyUp($event))"
        value="{{ evidenceField.value }}"
        (blur)="onEmit(elementService.OnInputBlur($event))"
        [disabled]="
          textInputValueisValid(evidenceField.value) &&
          element.params.lockedFields &&
            element.params.lockedFields.includes(evidenceField.name)
        "
      />
      <div class="error-field"
          [attr.validatorMessage] = "evidenceField.validatorMessage ? evidenceField.validatorMessage  : 'Field is required'"
          [attr.validatorMessageField] = "evidenceField.validatorMessage ? evidenceField.validatorMessage  : 'Field is required'">
      </div>
    </div>
  </div>
  <!-- INPUT HIDDEN -->
  <div class="element-wrapper" *ngSwitchCase="'inputHidden'">
    <!-- Input Text -->
    <input
      type="hidden"
      class="text-input"
      id="{{ evidenceField.id }}"
      name="{{ evidenceField.name }}"
      [attr.isValid]="true"
      [attr.isRequired]="
        element.investorSide || element.params.replaceFlow ? 'true' : 'false'
      "
      [attr.isMaxLength]="evidenceField.maxLength"
      [attr.isMinLength]="evidenceField.minLength"
      (keyup)="onEmit(elementService.OnInputKeyUp($event))"
      value="{{ evidenceField.value }}"
      (blur)="onEmit(elementService.OnInputBlur($event))"
    />
    <div
      class="error-field"
      [attr.validatorMessage]="'Field is required'"
      [attr.validatorMessageField]="'Field is required'"
    ></div>
  </div>
  <!-- SELECT OPTION -->
  <div
    class="element-wrapper input-transparent"
    *ngSwitchCase="'selectOptions'"
  >
    <div
      [ngClass]="{
        'd-none':
          (evidenceField.displayCondition || evidenceField.hideCondition) &&
          !isFieldChecked(
            evidenceField.displayCondition,
            evidenceField.hideCondition,
            evidenceField.id
          )
      }"
    >
      <span class="input-label font-weight-bold"
        >{{ evidenceField.label }}
        <sup class="sup-required">{{
          (element.investorSide && !evidenceField.optional) ||
          (element.params.replaceFlow && !evidenceField.optional)
            ? "*"
            : ""
        }}</sup></span
      >
      <span class="select-wrapper">
        <select
          class="select-input"
          id="{{ evidenceField.id }}"
          name="{{ evidenceField.name }}"
          (change)="onEmit(elementService.OnChangeSelect($event))"
          [attr.isRequired]="
            (element.investorSide && !evidenceField.optional) ||
            (element.params.replaceFlow && !evidenceField.optional)
              ? 'true'
              : 'false'
          "
          [value]="evidenceField.value ? evidenceField.value : -1"
          [disabled]="
            selectValueIsValid(
              evidenceField.value,
              evidenceField.selectOptionsList
            ) &&
            (evidenceField.disabled === true ||
              (element.params.lockedFields.includes(evidenceField.name) &&
                evidenceField.name !== 'CountryOfRegistration') ||
              (element.params.lockedFields.includes(evidenceField.name) &&
                element.params.manualSPfields))
          "
          [attr.skipParse]="evidenceField.skipParse"
          [attr.changemethod]="evidenceField.changemethod"
        >
          <option
            [selected]="evidenceField.value === option.key"
            value="{{ option.key }}"
            *ngFor="let option of evidenceField.selectOptionsList"
          >
            {{ option.name }}
          </option>
        </select>
      </span>
      <div
        class="error-field"
        [attr.validatorMessage]="element.params.validatorMessage"
        [attr.validatorMessageField]="element.params.validatorMessage"
      ></div>
    </div>
  </div>
  <!-- CHECKBOX -->
  <div
    [ngClass]="evidenceField.class ? evidenceField.class : ''"
    class="single-checkbox-wrapper"
    *ngSwitchCase="'checkbox'"
  >
    <input
      class="custom-checkbox"
      type="checkbox"
      id="{{ evidenceField.id }}"
      name="{{ evidenceField.name }}"
      [attr.skipParse]="evidenceField.skipParse"
      [checked]="evidenceField.checked"
    />
    <!-- (change)="OnChangeSelect($event)" -->
    <!-- (change)="onEmit(elementService.OnChangeSelect($event))" -->
    <label [for]="evidenceField.id" class="custom-checkbox-label">{{
      evidenceField.label
    }}</label>
  </div>
  <!-- GOOGLE ADDRESS -->
  <div
    class="element-wrapper"
    [ngClass]="evidenceField.class ? evidenceField.class : ''"
    *ngSwitchCase="'inputGoogleAutocomplete'"
  >
    <div id="mapImage" [ngClass]="!map ? '' : 'display-none'"></div>
    <div #mapWrapper id="map" [ngClass]="map ? '' : 'display-none'"></div>
    <div class="mapInfo" [ngClass]="map ? '' : 'display-none'">
      {{ "review.validation2" | translate }}
    </div>
    <span class="input-label {{ element.params.labelClass }}"
      >{{ "evidencePreview.addressLabel1" | translate
      }}<sup *ngIf="element.params.required" class="sup-required">*</sup></span
    >
    <!-- Input Text -->
    <input
      type="text"
      class="text-input google-autocomplete"
      #gautocomplete
      name="{{ element.params.name }}"
      value="{{ element.params.content }}"
      [attr.isValid]="true"
      [attr.isRequired]="element.params.required"
      [attr.isMinLength]="element.params.minLength"
      [attr.isMaxLength]="element.params.maxLength"
      [attr.patternMatch]="element.params.patternMatch"
      [attr.enterkeyaction]="element.params.onenterkeyaction"
      [attr.skipParse]="evidenceField.skipParse"
      placeholder="{{ 'evidencePreview.addressLabel2' | translate }}"
      autocomplete="chrome-off"
      (focus)="OnInitGoogleAutocomplete($event)"
      (keyup)="onEmit(elementService.OnInputKeyUp($event))"
      (blur)="onEmit(elementService.OnInputBlur($event))"
    />
    <div
      class="error-field"
      [attr.validatorMessage]="element.params.validatorMessage"
      [attr.validatorMessageField]="element.params.validatorMessage"
    ></div>
  </div>
  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'displayGoogleMap'"
  >
    <div
      #mapWrapper
      id="map"
      [ngClass]="map ? 'mobile-map' : 'display-none'"
    ></div>
  </div>
  <!-- DIVIDER TITLE -->
  <div class="element-wrapper input-transparent" *ngSwitchCase="'title'">
    <h4
      style="
        font-size: 20px;
        font-weight: 700;
        color: #333333;
        margin-bottom: 0px;
      "
      class="divider-title"
    >
      {{ evidenceField.label }}
      <sup class="sup-required">{{
        evidenceField.showAsRequired ? "*" : ""
      }}</sup>
    </h4>
  </div>
</div>
